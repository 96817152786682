import React from 'react';
import './app.css';

function App() {
  return (
    <>
    <div class="center">
      <img src="https://devpub-www.s3.amazonaws.com/static/core/img/dplogo_std.png" alt="Devilish Publishing Music Publishers" />
    </div>
    <div class="center">
      <h2>Ah... Hello there!</h2>
    </div>
    <div class="center">
      <p>You're a bit too early! We're currently developing this site as a PWA (Progressive Web App).
        You might have visited this page just to check if we have a .app TLD, and as you can see, we do!
        The best thing to do is let us get on with the job of building the app. We expect to go into 
        full production in summer 2023.</p>
    </div>
    <div class="center">
      <a href="https://devilishpublishing.com">In the meantime, click here to visit our main website</a>
    </div>
    </>
  )
}

export default App;
